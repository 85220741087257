import { MsalAuthProvider, LoginType } from 'react-aad-msal';

import environment from './environments/base';

const env = 'stage';
const settings = environment.authentication[env];

const domain = (typeof window !== 'undefined') ? settings.domain : '';
const mfaPolicy = (typeof window !== 'undefined') ? settings.mfaPolicy : '';
const mfaProfilePolicy = (typeof window !== 'undefined') ? settings.mfaProfilePolicy : '';
const mfaPasswordPolicy = (typeof window !== 'undefined') ? settings.mfaPasswordPolicy : '';

// Msal Configurations
const config = {
  auth: {
    authority: `${domain}/${mfaPolicy}`,
    clientId: (typeof window !== 'undefined') ? settings.clientId : '',
    validateAuthority: false,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: (typeof window !== 'undefined') ? window.location.href : null,
    // redirectUri: () => (typeof window !== 'undefined') ?  window.location.origin : null
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [
    'openid',
    'profile'
  ]
}

// Options
const options = {
  // loginType: LoginType.Popup
  loginType: LoginType.Redirect,
}

let MSalClientProvider = {};

if (typeof window !== 'undefined') {
  MSalClientProvider = new MsalAuthProvider(config, authenticationParameters, options);
}

export const authProvider = MSalClientProvider
export const authConfig = {
  domain,
  mfaPolicy,
  mfaProfilePolicy,
  mfaPasswordPolicy
}
